import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_RESOURCE_CATEGORIES_URL,
  GET_RESOURCE_CATEGORY_DETAIL_URL,
} from "../../../constants/url_helpers";
import { axiosPOCAdmin } from "../../../services/api";

export const fetchResourceCategoriesThunk = createAsyncThunk(
  "fetchResourceCategories",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()?.resourceCategory?.listing;
      const res = await axiosPOCAdmin.post(GET_RESOURCE_CATEGORIES_URL, {
        search: state.search,
        // sort_by: state?.sort_by || "id",
        // sort_order: state?.sort_order || "desc",
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchResourceCategoryThunk = createAsyncThunk(
  "fetchResourceCategoryThunk",
  async (payload, thunkAPI) => {
    try {
      const res = await axiosPOCAdmin.post(GET_RESOURCE_CATEGORY_DETAIL_URL, {
        category_id: payload,
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);
