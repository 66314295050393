const routesConstants = {
  HOME_PAGE: "dashboard",
  LOGIN: "login",
  SIGNUP: "signup",
  USERS: "users",
  REVENUE: "revenue",
  PAYMENT: "payment",
  SUBSCRIPTION: "transactions",
  USER_PROFILE: "profile",
  COUPON: "coupon",
  MANAGE_RESOURCE_CATEGORIES: "manage-resource-categories",
  MANAGE_AFFILIATE: "manage-affiliate",
};

export default routesConstants;
