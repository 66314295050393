import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sidebarLogo from "../assets/icons/sidebar-logo.svg";
import { menuLabels } from "../constants/menu";
import { clearSubscriptionConfig } from "../module/subscription/features/subscriptionSlice";
import { clearUsersConfig } from "../module/users/features/usersSlice";
import { setActivePage } from "../store/commonSlice";

const Sidebar = ({ isOpen, setIsopen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useLocation();
  const { activePage } = useSelector((state) => state.common);
  const ToggleSidebar = () => {
    !!isOpen ? setIsopen(false) : setIsopen(true);
  };

  useEffect(() => {
    dispatch(setActivePage(params?.pathname));
    dispatch(
      clearSubscriptionConfig(location?.state?.isResourceTab ? true : false)
    );
    dispatch(
      clearUsersConfig(
        location?.state?.type ? (location?.state?.type === 1 ? 2 : 1) : 0
      )
    );
    // dispatch(clearUsersConfig(location?.state?.type === 1 ? 2 : 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  useEffect(() => {
    window.innerWidth < 991 ? setIsopen(false) : setIsopen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  return (
    <>
      <div className="mt-3">
        <div className={`sidebar ${!!isOpen === true ? "active" : ""}`}>
          <div className="sd-header">
            <img src={sidebarLogo} alt="logo" className="sidebar-logo" />
          </div>
          <div className="sd-body">
            <ul>
              {menuLabels?.map((menu) => (
                <li
                  key={menu.id}
                  className={`sd-link ${
                    activePage === menu.link || activePage?.includes(menu.link)
                      ? // active == menu.id || menu.link === location.pathname
                        "active"
                      : ""
                  }`}
                  onClick={() => {
                    navigate(menu.link);
                    dispatch(setActivePage(menu.link));
                  }}
                >
                  {menu.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={`sidebar-overlay ${!!isOpen ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
};

export default Sidebar;
