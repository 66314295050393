import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styles from "./CustomPrompt.module.css";

const CustomPrompt = ({
  loading,
  title,
  extraMessage,
  isOpen,
  onClose,
  onHandleClick,
  loadingText = "Yes...",
  btnSave = "Yes",
  isCancel = true,
  btnClass = "danger",
}) => {
  return (
    <Modal size="md" isOpen={isOpen} centered>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <div className="pb-4">{extraMessage}</div>
        <div className="gap-3 d-flex justify-content-center">
          <button
            onClick={onHandleClick}
            className={`${
              btnClass === "primary" ? styles.btnPrimary : styles.btnDanger
            } ${styles.btn}`}
            disabled={loading}
          >{loading ? loadingText : btnSave}</button>
          {isCancel ? (
            <button
              onClick={onClose}
              disabled={loading}
              className={`${styles.btnSecondary} ${styles.btn}`}
            >Cancel</button>
          ) : null}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomPrompt;
