import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { Card } from "reactstrap";
import styles from "./PlanListing.module.scss";
import classNames from "../../../utils/classNames";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";

const subscriptionPlansType = {
  0: "Free",
  1: "Per Month",
  2: "Per Year",
  3: "Lifetime",
};

const PlanListing = ({
  onPlanClick,
  plans,
  error,
  planID,
  touched,
  showSelected,
}) => {
  const { loading, error: errorAPI } = useSelector(
    (state) => state?.common?.subscriptionPlans
  );

  if (loading) {
    return (
      <div
        style={{
          width: "28%",
          paddingTop: "10px",
        }}
      >
        <Loader />
      </div>
    );
  }
  if (errorAPI) {
    return (
      <div
        style={{
          borderRadius: "2px",
          maxWidth: "440px",
        }}
        className="card border border-danger"
      >
        <div className="card-body text-danger p-0">Something went wrong</div>
      </div>
    );
  }
  return (
    <div className={styles.plans_wrap}>
      {plans
        ?.filter((p) => (showSelected ? p.id === planID : true))
        ?.map((plan) => {
          return (
            <Card
              key={plan?.id}
              className={classNames(
                styles.plan_card,
                showSelected ? styles.disabled : ""
              )}
              onClick={() => {
                onPlanClick(plan);
              }}
            >
              <div
                className={classNames(
                  styles.radio,
                  error && touched ? styles.error : ""
                )}
              >
                {planID === plan?.id ? (
                  <IoMdRadioButtonOn />
                ) : (
                  <IoMdRadioButtonOff />
                )}
              </div>
              <div className={styles.plan_data}>
                <div className={styles.plan_texts}>
                  <div className={styles.plan_title}>{plan?.title}</div>
                  <div className={styles.plan_description}>
                    {plan?.description}
                  </div>
                </div>
                <div className={styles.plan_amount_wrap}>
                  <div className={styles.plan_amount}>${plan?.price}</div>
                  <div className={styles.plan_type}>
                    {subscriptionPlansType?.[plan?.duration_type]}
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
    </div>
  );
};
export default PlanListing;
