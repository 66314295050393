import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Breadcrumb = ({ title, type, extraLink }) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col sm={9}>
        <div className="breadcrumb-section">
          {type ? null : (
            <p
              className="breadcrumb-section pointer onhover"
              onClick={() => navigate("/")}
            >
              Home
            </p>
          )}
          {extraLink
            ? extraLink?.map((link, index) => (
                <div
                  key={index}
                  className="breadcrumb-section pointer onhover"
                  onClick={() => navigate(link.link)}
                >
                  <p>{link.title}</p>
                </div>
              ))
            : null}
          <p className="active">{title}</p>
        </div>
      </Col>
      <Col sm={3}>
        {type ? (
          <div className="text-muted text-end">
            Showing data from <span className="text-success">Last 30 Days</span>
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default Breadcrumb;
