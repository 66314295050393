import { createSlice } from "@reduxjs/toolkit";
import Cookies, { cookieKeys } from "../../../services/cookies";
import { adminLogin, logout } from "./authThunk";
import { toast } from "react-toastify";
import LocalStorage from "../../../services/localStorage";

const initToken = () => Cookies.get(cookieKeys.TOKEN);

const initialState = () => ({
  token: initToken() || "",
  isAuth: !!initToken(),
});

const authSlice = createSlice({
  initialState: initialState(),
  name: "auth",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state, { payload }) => {})
      .addCase(adminLogin.fulfilled, (state, { payload }) => {
        Cookies.set(cookieKeys.TOKEN, payload?.data?.access_token);
        Cookies.set(cookieKeys.REFRESH_TOKEN, payload?.data?.refresh_token);

        state.token = payload?.data?.access_token;
        state.isAuth = true;
        toast.success(payload?.message || "Login successful");
      })
      .addCase(adminLogin.rejected, (state, { payload }) => {
        toast.error(payload?.message || "Failed");
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        Cookies.clear();
        LocalStorage.clear();
        state = initialState();
        window.location.reload();
      });
  },
});

export default authSlice.reducer;
