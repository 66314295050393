import classNames from "../../utils/classNames";
import styles from "./index.module.scss";

const FormikTextarea = ({
  error,
  touched,
  className,
  labelClassName,
  resize = false,
  rows = 3,
  placeholder,
  ...props
}) => {
  return (
    <label className={classNames(styles.wrap, labelClassName)}>
      <span className={styles.field_name}>{placeholder}</span>
      <textarea
        placeholder={placeholder}
        rows={rows}
        {...props}
        className={classNames(
          styles.textarea,
          className,
          error && touched ? styles.error : "",
          resize ? styles.resize : ""
        )}
      />
      {touched && error ? (
        <span className={classNames(styles.error_text, "text-start")}>
          {"error"}
        </span>
      ) : null}
    </label>
  );
};
export default FormikTextarea;
