import { Navigate, Outlet } from "react-router-dom";
import CreateCoupon from "../module/coupon/pages/CreateCoupon";
import routesConstants from "./routeConstant";
import {
  AffiliateDetailsPage,
  CouponDetailsPage,
  CouponPage,
  CreateEditAffiliatePage,
  DashboardPage,
  EditCouponPage,
  LoginPage,
  ManageAffiliatePage,
  ManageResourceCategoryPage,
  ProfilePage,
  SubscriptionPage,
  UsersPage,
} from "./routeImport";

const routesConfig = {
  private: [
    {
      path: routesConstants.HOME_PAGE,
      Component: DashboardPage,
    },
    {
      path: routesConstants.USERS,
      Component: UsersPage,
    },
    {
      path: routesConstants.SUBSCRIPTION,
      Component: SubscriptionPage,
    },
    {
      path: `${routesConstants.USER_PROFILE}/:uuid`,
      Component: ProfilePage,
    },
    {
      path: routesConstants.COUPON,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: CouponPage,
        },
        {
          path: "create",
          Component: CreateCoupon,
        },
        {
          path: "edit",
          Component: Outlet,
          children: [
            {
              index: true,
              Component: () => <Navigate to={"/" + routesConstants.COUPON} />,
            },
            {
              path: ":couponEditID",
              Component: EditCouponPage,
            },
          ],
        },
        {
          path: ":couponID",
          Component: CouponDetailsPage,
        },
      ],
    },
    {
      path: routesConstants.MANAGE_RESOURCE_CATEGORIES,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: ManageResourceCategoryPage,
        },
      ],
    },
    {
      path: routesConstants.MANAGE_AFFILIATE,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: ManageAffiliatePage,
        },
        {
          path: "create",
          Component: CreateEditAffiliatePage,
        },
        {
          path: "edit/:affiliateEditID",
          Component: CreateEditAffiliatePage,
        },
        {
          path: ":affiliateID",
          Component: AffiliateDetailsPage,
        },
      ],
    },
  ],
  public: [
    {
      path: "/",
      Component: LoginPage,
    },
    {
      path: "/login",
      Component: LoginPage,
    },
  ],
};

export default routesConfig;
