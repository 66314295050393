import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAffiliateDetailsThunk,
  fetchAllAffiliatesThunk,
} from "./affiliateThunk";

const initialState = {
  listing: {
    loading: false,
    list: [],
    error: null,
    //
    page: 1,
    limit: 10,
    total: 0,
    //
    search: "",
    sort_by: "poc_affiliate_org_id",
    sort_order: "asc",
  },
  details: {
    data: null,
    loading: false,
    error: null,
  },
};

const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    updateAffiliateListingPayload: (state, { payload }) => {
      if (payload && typeof payload === "object") {
        Object.entries(payload).forEach(([key, value]) => {
          if (key in state.listing) {
            state.listing[key] = value;
          }
        });
      }
    },
    resetAffiliateDetails: (state) => {
      state.details = Object.assign({}, initialState.details);
    },
    updateAffiliateTransaction: (state, { payload }) => {
      if (state.details.data?.monthlyVisitorsConvertedUsersList) {
        state.details.data.monthlyVisitorsConvertedUsersList =
          state.details.data.monthlyVisitorsConvertedUsersList.map((m) =>
            m?.YearMonthNeumaric === payload?.YearMonthNeumaric
              ? { ...m, is_paid: payload.is_paid }
              : m
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAffiliatesThunk.fulfilled, (state, { payload }) => {
        state.listing.list = payload.rows;
        state.listing.loading = false;
        state.listing.error = false;
        state.listing.total = payload?.count;
      })
      .addCase(fetchAllAffiliatesThunk.rejected, (state, { payload }) => {
        state.listing.list = [];
        state.listing.loading = false;
        state.listing.error = payload;
      })
      .addCase(fetchAllAffiliatesThunk.pending, (state) => {
        state.listing.loading = true;
        state.listing.error = false;
      });

    //
    builder
      .addCase(fetchAffiliateDetailsThunk.fulfilled, (state, { payload }) => {
        state.details.data = payload;
        state.details.loading = false;
        state.details.error = false;
      })
      .addCase(fetchAffiliateDetailsThunk.rejected, (state, { payload }) => {
        state.details.data = null;
        state.details.loading = false;
        state.details.error = payload;
      })
      .addCase(fetchAffiliateDetailsThunk.pending, (state) => {
        state.details.loading = true;
        state.details.error = false;
      });
  },
});

export const {
  updateAffiliateListingPayload,
  resetAffiliateDetails,
  updateAffiliateTransaction,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;
