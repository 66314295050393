import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPOCAdmin } from "../../../services/api";
import {
  COUPON_DETAILS_URL,
  COUPON_LISTING_URL,
} from "../../../constants/url_helpers";

export const fetchCouponDetailsThunk = createAsyncThunk(
  "coupon/fetchCouponDetailsThunk",
  async (payload, thunkAPI) => {
    try {
      const res = await axiosPOCAdmin.post(COUPON_DETAILS_URL, {
        coupon_id: payload,
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCouponListing = createAsyncThunk(
  "coupon/fetchCouponListing",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState()?.coupon?.listing;
      const res = await axiosPOCAdmin.post(COUPON_LISTING_URL, {
        search: state.search,
        page: state.page || 1,
        limit: state.limit || 10,
        sort_by: state?.sort_by || "id",
        sort_order: state?.sort_order || "desc",
        status: state?.status,
        is_private: state?.is_private,
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);
