import { toast } from "react-toastify";
import {
  GET_ADMIN_DASHBOARD_DATA,
  GET_USER_PAYMENT_HISTORY,
} from "../../../constants/url_helpers";
import { axiosPOCAdmin } from "../../../services/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  dashboardLoading: false,
  dashboardData: {
    recentTransactionData: [],
    recentTransactionDataModified: [],
    resourcesData: [],
    subscriptionData: [],
    topEarningCoachesData: [],
    topPayingProfessionalData: [],
    transactionReceivedData: [],
    totalActiveUsersData: 0,
    totalSubscribers: [],
    totalSubscriptionRevenue: [],
  },
  userPaymentHistory: {
    loading: false,
    page: 1,
    limit: 10,
    count: 0,
    data: [],
  },
};

const dashboardSlice = createSlice({
  initialState,
  name: "dashboard",
  reducers: {
    setDashboardLoading: (state, action) => ({
      ...state,
      dashboardLoading: action.payload,
    }),
    setDashboardData: (state, action) => ({
      ...state,
      dashboardData: action.payload,
    }),
    setUserPaymentLoading: (state, action) => ({
      ...state,
      userPaymentHistory: {
        ...state.userPaymentHistory,
        loading: action.payload,
      },
    }),
    setUserPaymentPage: (state, action) => ({
      ...state,
      userPaymentHistory: {
        ...state.userPaymentHistory,
        page: action.payload,
      },
    }),
    setUserPaymentLimit: (state, action) => ({
      ...state,
      userPaymentHistory: {
        ...state.userPaymentHistory,
        limit: action.payload,
      },
    }),
    clearUserPaymentConfig: (state, action) => ({
      ...state,
      userPaymentHistory: {
        ...state.userPaymentHistory,
        limit: 10,
        page: 1,
        data: [],
        loading: false
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResourcesAdminList.fulfilled, (state, action) => ({
      ...state,
      dashboardData: {
        ...state.dashboardData,
        recentTransactionData: action?.payload?.recentTransactionData || [],
        recentTransactionDataModified:
          action.payload?.recentTransactionData?.rows.map((data) => ({
            id: data.id,
            chardId: data?.charge_id,
            paidbyName: data?.User?.name,
            paidByEmail: data?.User?.email,
            paidFor: data?.PaymentHistory?.paid_for,
            totalAmount: data?.charge_amount,
            createdAt: data?.created_at,
            paymentStatus: data?.PaymentHistory?.status,
            totalCommision: (data?.charge_amount * 12.5) / 100,
          })),
        resourcesData: action.payload?.resourcesData?.rows,
        subscriptionData: action.payload?.subscriptionData,
        topEarningCoachesData: action.payload?.topEarningCoachesData,
        topPayingProfessionalData: action.payload?.topPayingProfessionalData,
        transactionReceivedData: action.payload?.transactionReceivedData,
        totalActiveUsersData: action.payload?.totalActiveUsersData || 0,
        totalSubscribers: action.payload?.totalSubscribers[0] || [],
        totalSubscriptionRevenue:
          action.payload?.totalSubscriptionRevenue || [],
      },
    }));
    builder.addCase(fetchUserPaymentHistories.fulfilled, (state, action) => ({
      ...state,
      userPaymentHistory: {
        ...state.userPaymentHistory,
        data: action.payload.rows.map((row) => row.PaymentHistory),
        count: action.payload.count,
      },
    }));
  },
});

export const fetchResourcesAdminList = createAsyncThunk(
  "resources_list",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setDashboardLoading(true));
      const response = await axiosPOCAdmin.get(GET_ADMIN_DASHBOARD_DATA);
      if (response) {
        thunkAPI.dispatch(setDashboardLoading(false));
        return response.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(setDashboardLoading(false));
      toast.error(err);
    }
  }
);

export const fetchUserPaymentHistories = createAsyncThunk(
  "user_paymentHistory",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setUserPaymentLoading(true));
      const response = await axiosPOCAdmin.post(
        GET_USER_PAYMENT_HISTORY,
        payload
      );
      if (response) {
        thunkAPI.dispatch(setUserPaymentLoading(false));
        return response.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(setUserPaymentLoading(false));
      toast.error(err);
    }
  }
);

export const {
  setDashboardData,
  setDashboardLoading,
  setUserPaymentLoading,
  setUserPaymentLimit,
  setUserPaymentPage,
  clearUserPaymentConfig
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
