import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import FormikTextField from "../../../components/FormikTextField";
import styles from "./CouponEmails.module.scss";
import classNames from "../../../utils/classNames";

const CouponEmails = ({
  onAdd,
  onRemove,
  emails,
  touched,
  errors,
  handleBlur,
  onChange,
  readOnly,
}) => {
  return (
    <div className={styles.emails_section}>
      <div className={styles.top_section}>
        <div className={styles.section_title}>Emails</div>
      </div>
      <div className={styles.emails_list}>
        {emails.map((item, index, arr) => {
          return (
            <div key={item.uuid} className={styles.email_line}>
              <FormikTextField
                readOnly={readOnly}
                hideErrorText
                hideTitle
                touched={touched?.[index]?.email}
                error={errors?.[index]?.email}
                value={item.email}
                onChange={onChange}
                onBlur={handleBlur}
                name={`emails.${index}.email`}
              />
              {!readOnly ? (
                <div className={styles.btns}>
                  <div
                    className={classNames(
                      styles.icon,
                      arr?.length - 1 !== index ? styles.hide : ""
                    )}
                    onClick={() => {
                      onAdd();
                    }}
                  >
                    <FaPlusCircle />
                  </div>
                  <div
                    className={classNames(
                      styles.icon,
                      index ? "" : styles.hide
                    )}
                    onClick={() => {
                      onRemove(item?.uuid);
                    }}
                  >
                    <FaMinusCircle />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CouponEmails;
