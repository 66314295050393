import { createSlice } from "@reduxjs/toolkit";
import { fetchCouponDetailsThunk, fetchCouponListing } from "./couponThunk";

const initialState = {
  data: null,
  loading: false,
  error: null,
  usersTable: {
    sortField: "id",
    sortOrder: "desc",
  },
  listing: {
    loading: false,
    error: null,
    data: [],
    page: 1,
    search: "",
    limit: 10,
    sort_by: "created_at",
    sort_order: "desc",
    total: 0,
    status: undefined,
    is_private: undefined,
  },
};

const couponSlice = createSlice({
  initialState,
  name: "coupon",
  reducers: {
    updateCouponStatus: (state, { payload }) => {
      if ("coupon" in state.data) {
        if ("status" in state.data.coupon) {
          state.data.coupon.status = payload;
        }
      }
    },
    updateCouponListingKeyValue: (state, { payload }) => {
      (Array.isArray(payload) ? payload : [payload]).forEach(
        ({ key, value }) => {
          if (key in state.listing) {
            state.listing[key] = value;
          }
        }
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouponDetailsThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchCouponDetailsThunk.rejected, (state, { payload }) => {
        state.data = null;
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchCouponDetailsThunk.pending, (state) => {
        state.loading = true;
        state.error = false;
      });

    // listing
    builder
      .addCase(fetchCouponListing.fulfilled, (state, { payload }) => {
        state.listing.data = payload.rows;
        state.listing.loading = false;
        state.listing.error = false;
        state.listing.total = payload?.count;
      })
      .addCase(fetchCouponListing.rejected, (state, { payload }) => {
        state.listing.data = [];
        state.listing.loading = false;
        state.listing.error = payload;
      })
      .addCase(fetchCouponListing.pending, (state) => {
        state.listing.loading = true;
        state.listing.error = false;
      });
  },
});

export const { updateCouponListingKeyValue, updateCouponStatus } =
  couponSlice.actions;

export default couponSlice.reducer;
