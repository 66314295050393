import classNames from "../utils/classNames";
import styles from "./Loader.module.css";

const Loader = ({ className }) => {
  return (
    <div className={classNames(styles.loader_wrap, className)}>
      <div className={styles.loader} />
    </div>
  );
};
export default Loader;
