import routesConstants from "../routes/routeConstant";
import getRoute from "../routes/utils/getRoute";

export const menuLabels = [
  { id: 1, label: "Dashboard", link: "/dashboard" },
  // {id:2, label: "Payment", link:"/payment"},
  { id: 3, label: "Manage Users", link: "/users" },
  { id: 4, label: "Manage Transactions", link: "/transactions" },
  { id: 5, label: "Manage Coupons", link: "/coupon" },
  {
    id: 6,
    label: "Manage Resource Categories",
    link: "/" + routesConstants.MANAGE_RESOURCE_CATEGORIES,
  },
  {
    id: 7,
    label: "Manage Affiliates",
    link: getRoute({ routes: routesConstants.MANAGE_AFFILIATE }),
  },
  // {id:5, label: "Revenue", link:"/revenue"},
];
