import { useMemo } from "react";
import classNames from "../../../utils/classNames";
import styles from "./CouponDiscount.module.scss";
import FormikTextField from "../../../components/FormikTextField";
import { Col, Row } from "reactstrap";

const CouponDiscount = ({
  duration_type,
  plan_id,
  discount_amount,
  discount_type,
  error,
  touched,
  setFieldValue,
  setFieldTouched,
  validateField,
  handleBlur,
  readOnly,
}) => {
  const { placeHolderText, discountType } = useMemo(() => {
    let text = "";
    let type = "";
    switch (discount_type) {
      case 1:
        text = "Enter discount amount";
        type = "($)";
        break;
      case 2:
        text = "Enter discount percentage";
        type = "(%)";
        break;
      case 3:
        text = "Enter free trial days";
        type = "(days)";
        break;
      default:
        text = "";
        break;
    }
    return { placeHolderText: "Enter value", discountType: type };
  }, [discount_type]);

  return (
    <div className={styles.discount_wrap}>
      <Row className="gap-3 gap-xl-0">
        <Col xs={12} xl={7}>
          <div className={styles.section_title}>Discount Type</div>
          <div className={styles.discount_types_wrap}>
            {[
              {
                title: "Flat",
                disable: [1, 2].includes(duration_type) || readOnly,
                id: 1,
              },
              {
                title: "Percentage",
                disable: [1, 2].includes(duration_type) || readOnly,
                id: 2,
              },
              {
                title: "Free Trial",
                disable: [3].includes(duration_type) || readOnly,
                id: 3,
              },
            ].map((item) => {
              return (
                <div
                  key={item.title}
                  className={classNames(
                    styles.discount_type,
                    item.disable ? styles.disable : "",
                    item.id === discount_type ? styles.active : ""
                  )}
                  onClick={() => {
                    setFieldValue("discount_type", item.id);
                    setFieldValue("discount_amount", "");
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs={12} xl={5}>
          <div className={classNames(styles.section_title, "text-capitalize")}>
            Discount {discountType}
          </div>
          <FormikTextField
            labelClassName={styles.label}
            disabled={readOnly}
            error={error}
            touched={touched}
            type="number"
            min={0}
            placeholder={placeHolderText}
            value={discount_amount}
            name="discount_amount"
            onChange={(e) => {
              const name = e.target.name;
              setFieldValue(name, e.target.value).then(() =>
                validateField(name)
              );
            }}
            allowDecimal={false}
            onBlur={handleBlur}
            hideTitle
            step={1}
          />
        </Col>
      </Row>
    </div>
  );
};
export default CouponDiscount;
