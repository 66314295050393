import { GET_SUBSCRIPTION_PLANS } from "../constants/url_helpers";
import { axiosPOCAdmin } from "../services/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// thunks
export const fetchSubscriptionPlansThunk = createAsyncThunk(
  "common/fetchSubscriptionPlansThunk",
  async (_, thunkAPI) => {
    try {
      const res = await axiosPOCAdmin.get(GET_SUBSCRIPTION_PLANS);
      const data = await res.data;
      return data?.data?.plans;
    } catch (e) {
      const error = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// slice
const initialState = {
  activePage: "/dashboard",
  subscriptionPlans: {
    loading: false,
    list: null,
    error: null,
  },
};

const commonSlice = createSlice({
  initialState,
  name: "common",
  reducers: {
    setActivePage: (state, action) => ({
      ...state,
      activePage: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionPlansThunk.fulfilled, (state, { payload }) => {
        state.subscriptionPlans.list = payload;
        state.subscriptionPlans.loading = false;
        state.subscriptionPlans.error = false;
      })
      .addCase(fetchSubscriptionPlansThunk.pending, (state) => {
        state.subscriptionPlans.loading = true;
        state.subscriptionPlans.error = false;
      })
      .addCase(fetchSubscriptionPlansThunk.rejected, (state, { payload }) => {
        state.subscriptionPlans.list = null;
        state.subscriptionPlans.loading = false;
        state.subscriptionPlans.error = payload;
      });
  },
});

export const { setActivePage } = commonSlice.actions;

export default commonSlice.reducer;
