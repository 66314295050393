import { createSlice } from "@reduxjs/toolkit";
import {
  fetchResourceCategoriesThunk,
  fetchResourceCategoryThunk,
} from "./resourceCategoryThunk";

const initState = {
  details: {
    data: null,
  },
  listing: {
    data: [],
    loading: false,
    error: null,
    // sorting
    // sort_by: "created_at",
    // sort_order: "desc",
    // filters
    search: "",
  },
};

const resourceCategorySlice = createSlice({
  name: "resourceCategories",
  initialState: initState,
  reducers: {
    updateResourceCategoryListingKeyValue: (state, { payload }) => {
      (Array.isArray(payload) ? payload : [payload]).forEach(
        ({ key, value }) => {
          if (key in state.listing) {
            state.listing[key] = value;
          }
        }
      );
    },
    resetResourceCategoryDetails: (state) => {
      state.details = JSON.parse(JSON.stringify(initState.details));
    },
    updateResourceCategory: (state, { payload }) => {
      state.details.data = payload;
    },
    addResourceCategory: (state, { payload }) => {
      if (state.listing.data) {
        state.listing.data.push(payload);
      } else {
        state.listing.data = [payload];
      }
    },
    addUpdateCategory: (state, { payload }) => {
      // update subcategory in listing
      const index = state.listing.data.findIndex(
        (l) =>
          l?.poc_resource_category_subcategory_id ===
          payload?.poc_resource_category_subcategory_id
      );
      if (index !== -1) {
        if (state.listing.data[index]) {
          state.listing.data[index] = payload;
        }
      }

      // update subcategory in category details
      state.details.data = payload;
    },
    updateResourceCategoriesList: (state, { payload }) => {
      state.listing.data = payload;
    },
  },
  extraReducers: (builder) => {
    // listing
    builder
      .addCase(fetchResourceCategoriesThunk.fulfilled, (state, { payload }) => {
        state.listing.data = payload.rows;
        state.listing.loading = false;
        state.listing.error = false;
        state.listing.total = payload?.count;
        //details
        state.details.data = payload.rows?.[0] || null;
      })
      .addCase(fetchResourceCategoriesThunk.rejected, (state, { payload }) => {
        state.listing.data = [];
        state.listing.loading = false;
        state.listing.error = payload;
      })
      .addCase(fetchResourceCategoriesThunk.pending, (state) => {
        state.listing.loading = true;
        state.listing.error = false;
      });

    //   detail
    builder
      .addCase(fetchResourceCategoryThunk.fulfilled, (state, { payload }) => {
        state.details.data = payload;
        state.details.loading = false;
        state.details.error = false;
      })
      .addCase(fetchResourceCategoryThunk.rejected, (state, { payload }) => {
        state.details.data = null;
        state.details.loading = false;
        state.details.error = payload;
      })
      .addCase(fetchResourceCategoryThunk.pending, (state) => {
        state.details.loading = true;
        state.details.error = false;
      });
  },
});

export const {
  updateResourceCategoryListingKeyValue,
  resetResourceCategoryDetails,
  updateResourceCategory,
  addResourceCategory,
  addUpdateCategory,
  updateResourceCategoriesList,
} = resourceCategorySlice.actions;

export default resourceCategorySlice.reducer;
