import classNames from "../../utils/classNames";
import styles from "./index.module.scss";

const FormikTextField = ({
  error,
  touched,
  className,
  labelClassName,
  type = "text",
  value = "",
  onChange,
  placeholder,
  title,
  infoText,
  hideTitle,
  hideErrorText,
  allowDecimal = true,
  ...props
}) => {
  return (
    <label className={classNames("m-0 mb-2", styles.wrap, labelClassName)}>
      {!hideTitle ? (
        <span className={styles.field_name}>{title || placeholder}</span>
      ) : null}
      <input
        className={classNames(
          styles.form_field,
          className,
          error && touched ? styles.error : ""
        )}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onWheel={(e) => {
          if (type === "number") {
            e.target.blur();
            e.stopPropagation();
            setTimeout(() => {
              e.target.focus();
            }, 0);
          }
        }}
        onKeyDown={(e) => {
          if (type === "number") {
            if (["e", "E", "+", "-", "Enter"].includes(e.key)) {
              e.preventDefault();
            }
            if (!allowDecimal && e.key === ".") {
              e.preventDefault();
            }
          }
        }}
        type={type}
        {...props}
      />

      {touched && error && !hideErrorText ? (
        <span className={styles.error_text}>{error}</span>
      ) : !!infoText ? (
        <span className={styles.info_text}>{infoText}</span>
      ) : null}
    </label>
  );
};
export default FormikTextField;
