import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPOCAdmin } from "../../../services/api";
import { ADMIN_LOGIN, LOGOUT } from "../../../constants/url_helpers";

export const adminLogin = createAsyncThunk("auth/adminLogin", async (payload, thunk) => {
  try {
    const res = await axiosPOCAdmin.post(ADMIN_LOGIN, payload);
    let json = await res.data;
    return json;
  } catch (e) {
    return thunk.rejectWithValue(e?.response?.data);
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, thunk) => {
  try {
    const res = await axiosPOCAdmin.get(LOGOUT);
    let json = await res.data;
    return json;
  } catch (e) {
    return thunk.rejectWithValue(e?.response?.data);
  }
});
