import React, { useState } from "react";

import { BiMenuAltLeft } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { logout } from "../module/login/features/authThunk";
import CustomPrompt from "../components/Prompt/CustomPrompt";
import { useLocation } from "react-router-dom";
import { menuLabels } from "../constants/menu";

const Header = ({ isOpen, setIsopen }) => {
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const [logoutModal, setLogoutModal] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md header-top-section">
        <div className="container-fluid p-2">
          <div className="form-inline ml-auto d-flex">
            <div className="header-toggle pointer" onClick={ToggleSidebar}>
              <BiMenuAltLeft className="menu-icon" />
            </div>
            <p className="navbar-brand text-primary ms-2 mb-0">
              {location.pathname.includes("/profile")
                ? "Profile"
                : menuLabels.find((x) => location.pathname.includes(x.link))
                    .label}
            </p>
          </div>
          <div
            className="me-3 pointer logout"
            onClick={() => {
              setLogoutModal(true);
              // dispatch(logout());
            }}
          >
            Logout
          </div>
        </div>
        <CustomPrompt
          isOpen={logoutModal}
          onClose={() => setLogoutModal(false)}
          onHandleClick={() => {
            setIsLogout(true);
            dispatch(logout()).then((res) => {
              setIsLogout(false);
            });
          }}
          extraMessage={"Are you sure you want to Logout?"}
          title={"Logout?"}
          loading={isLogout}
        />
      </nav>
    </>
  );
};

export default Header;
