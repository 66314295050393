export const ADMIN_LOGIN = "/adminLogin";
export const LOGOUT = "/logout";
export const ListAllUserData = "/listAllUserData";
export const GET_ADMIN_DASHBOARD_DATA = "/adminDashboardData";
export const GET_USER = "/users";
export const GET_USER_REVIEW_LIST_URL = "/getUserReviewList";
export const ACTIVE_DEACTIVE_USER = "/activeDeactiveUser";
export const GET_PAYMENT_RECEIPT_URL = "/getPaymentReceiptUrl";
export const GET_SUBSCRIPTION_LIST_DATA = "/listManageSubscriptions";
export const GET_USER_PAYMENT_HISTORY = "/getUserPaymentHistories";

export const GET_SUBSCRIPTION_PLANS = "/subscriptionPlansForAdmin";
export const COUPON_DETAILS_URL = "/couponDetail";
export const COUPON_LISTING_URL = "/listCoupons";

export const ADD_UPDATE_COUPON_URL = "/addUpdateCoupon";

// manage resource categories
export const GET_RESOURCE_CATEGORIES_URL = "/listResourceCategories";
export const GET_RESOURCE_CATEGORY_DETAIL_URL = "/resourceCategoryDetail";
export const ADD_UPDATE_RESOURCE_CATEGORY_URL = "/addUpdateResourceCategory";
export const ORDER_RESOURCE_CATEGORIES_URL = "/orderResourceCategories";
export const DELETE_RESOURCE_CATEGORY_URL = "/deleteResourceCategory";

// affiliates
export const LIST_AFFILIATES_URL = "/listAffiliates";
export const AFFILIATE_DETAILS_URL = "/affiliateDetails";
export const GENERATE_AFFILIATE_REF_CODE_URL = "/generateAffiliateRefCode";
export const VALIDATE_AFFILIATE_REF_CODE_URL = "/validateAffiliateRefCode";
export const ADD_UPDATE_AFFILIATE_ORG_URL = "/addUpdateAffiliateOrg";
export const DELETE_AFFILIATE_ORG_URL = "/deleteAffiliateOrg";
export const TOGGLE_AFFILIATE_TRANSACTION_HISTORY_URL =
  "/toggleAffiliateTransactionHistory";
