import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPOCAdmin } from "../../../services/api";
import {
  AFFILIATE_DETAILS_URL,
  LIST_AFFILIATES_URL,
} from "../../../constants/url_helpers";

export const fetchAllAffiliatesThunk = createAsyncThunk(
  "affiliate/fetchAllAffiliatesThunk",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()?.affiliate?.listing;
      const res = await axiosPOCAdmin.post(LIST_AFFILIATES_URL, {
        search: state.search,
        sort_by: state?.sort_by,
        sort_order: state?.sort_order,
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAffiliateDetailsThunk = createAsyncThunk(
  "coupon/fetchAffiliateDetailsThunk",
  async (payload, thunkAPI) => {
    try {
      const res = await axiosPOCAdmin.post(AFFILIATE_DETAILS_URL, {
        org_id: payload,
      });
      const data = await res.data;
      return data?.data;
    } catch (e) {
      const err = e?.response?.data?.message || e?.message;
      return thunkAPI.rejectWithValue(err);
    }
  }
);
