import lazy from "../utils/lazy";

export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "../module/login")
);

export const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ "../module/dashboard")
);

export const UsersPage = lazy(() =>
  import(/* webpackChunkName: "UsersPage" */ "../module/users")
);

export const SubscriptionPage = lazy(() =>
  import(/* webpackChunkName: "SubscriptionPage" */ "../module/subscription")
);

export const ProfilePage = lazy(() =>
  import(/* webpackChunkName: "ProfilePage" */ "../module/users/UserProfile")
);

export const CreateCouponPage = lazy(() =>
  import(
    /* webpackChunkName: "CreateCouponPage" */ "../module/coupon/pages/CreateCoupon"
  )
);

export const EditCouponPage = lazy(() =>
  import(
    /* webpackChunkName: "EditCouponPage" */ "../module/coupon/pages/EditCoupon"
  )
);

export const CouponDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "CouponDetailsPage" */ "../module/coupon/pages/CouponDetails"
  )
);

export const CouponPage = lazy(() =>
  import(/* webpackChunkName: "CouponPage" */ "../module/coupon")
);

export const ManageResourceCategoryPage = lazy(() =>
  import(
    /* webpackChunkName: "ManageResourceCategoryPage" */ "../module/resourceCategories"
  )
);

export const ManageAffiliatePage = lazy(() =>
  import(/* webpackChunkName: "ManageAffiliatePage" */ "../module/affiliate")
);

export const CreateEditAffiliatePage = lazy(() =>
  import(
    /* webpackChunkName: "CreateEditAffiliatePage" */ "../module/affiliate/pages/CreateEditAffiliate"
  )
);

export const AffiliateDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "AffiliateDetailsPage" */ "../module/affiliate/pages/AffiliateDetails"
  )
);
