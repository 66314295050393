import { toast } from "react-toastify";
import { GET_SUBSCRIPTION_LIST_DATA } from "../../../constants/url_helpers";
import { axiosPOCAdmin } from "../../../services/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  subscription: {
    subscriptionData: [],
    subscriptionDataModified: [],
    currPage: 1,
    sizePerPage: 50,
    filter: 0,
    totalCount: 0,
    loading: false,
    sortField: "createdAt",
    sortOrder: "desc",
    search: "",
  },
};

const subscriptionSlice = createSlice({
  initialState,
  name: "subscription",
  reducers: {
    setSubscriptionDataLoading: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        loading: action.payload,
      },
    }),
    setSubscriptionPage: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        currPage: action.payload,
      },
    }),
    setSubscriptionPageSize: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        sizePerPage: action.payload,
      },
    }),
    setSubscriptionFilter: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        filter: action.payload,
      },
    }),
    setSubscriptionSortField: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        sortField: action.payload,
      },
    }),
    setSubscriptionSortOrder: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        sortOrder: action.payload,
      },
    }),
    setSubscriptionSearch: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        search: action.payload,
      },
    }),
    clearSubscriptionConfig: (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        sortOrder: "desc",
        sortField: action.payload ? "totalAmount" : "createdAt",
        currPage: 1,
        sizePerPage: 50,
        search: "",
        filter: action.payload ? 2 : 0,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionList.fulfilled, (state, action) => ({
      ...state,
      subscription: {
        ...state.subscription,
        subscriptionData: action.payload.rows || [],
        subscriptionDataModified: action.payload?.rows.map((data) => ({
          id: data.id,
          chardId: data?.charge_id,
          paidByName: data?.ProfessionalTransfer?.name,
          paidByEmail: data?.ProfessionalTransfer?.email,
          paidToName:
            data?.coachTransfer?.name === "Admin"
              ? "CDB"
              : data?.coachTransfer?.name,
          paidToEmail: data?.coachTransfer?.email,
          paidFor: data?.PaymentHistory?.paid_for,
          totalAmount: data?.charge_amount,
          createdAt: data?.created_at,
          paymentStatus: data?.PaymentHistory?.status,
          totalCommission: data?.commission_rate,
          stripeCharges: data?.stripe_charges || 0,
          coachReceive: data?.transfered_amount || 0,
        })),
        totalCount: action.payload.count || 0,
      },
    }));
  },
});

export const fetchSubscriptionList = createAsyncThunk(
  "subscription_list",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setSubscriptionDataLoading(true));
      const response = await axiosPOCAdmin.post(
        GET_SUBSCRIPTION_LIST_DATA,
        payload
      );
      if (response) {
        thunkAPI.dispatch(setSubscriptionDataLoading(false));
        return response.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(setSubscriptionDataLoading(false));
      toast.error(err);
    }
  }
);

export const {
  setSubscriptionPage,
  setSubscriptionSearch,
  setSubscriptionFilter,
  setSubscriptionPageSize,
  setSubscriptionSortField,
  setSubscriptionSortOrder,
  setSubscriptionDataLoading,
  clearSubscriptionConfig,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
