import { createSlice } from "@reduxjs/toolkit";
import { activeDeactiveUser, userProfile, usersList } from "./usersThunk";
import { toast } from "react-toastify";

const initialState = () => ({
  usersList: {
    data: [],
    loading: false,
    page: 1,
    sizePerPage: 50,
    totalData: 0,
    type: 0,
    sortField: "id",
    sortOrder: "desc",
  },
  profile: {
    data: {
      reviews: {
        page: 1,
        limit: 5,
        rows: [],
        count: 0,
        ratings: 0,
        error: null,
        loading: false,
      },
    },
    loading: false,
  },
});

const userSlice = createSlice({
  initialState: initialState(),
  name: "user",
  reducers: {
    updateReviewPayload: (state, { payload = {} }) => {
      Object.entries(payload).forEach(([key, value]) => {
        if (state.data.reviews?.[key]) {
          state.data.reviews[key] = value;
        }
      });
    },
    updateUserListingFilter: (state, { payload }) => {
      state.usersList.type = payload;
    },
    updateCurrentPage: (state, { payload: { page, sizePerPage } }) => {
      state.usersList.page = page;
      state.usersList.sizePerPage = sizePerPage;
    },
    updateSortField: (state, { payload: { sortField, sortOrder } }) => {
      state.usersList.sortField = sortField;
      state.usersList.sortOrder = sortOrder;
    },
    clearUsersConfig: (state, action) => ({
      ...state,
      usersList: {
        ...state.usersList,
        sortOrder: "desc",
        sortField: "id",
        page: 1,
        sizePerPage: 50,
        search: "",
        type: action?.payload || 0,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersList.pending, (state, { payload }) => {
        state.usersList.loading = true;
      })
      .addCase(usersList.fulfilled, (state, { payload }) => {
        state.usersList.loading = false;
        state.usersList.data = payload?.data?.rows;
        state.usersList.totalData = payload?.data?.count;
      })
      .addCase(usersList.rejected, (state, { payload }) => {
        state.usersList.loading = false;
      })
      .addCase(userProfile.pending, (state, { payload }) => {
        state.profile.loading = true;
      })
      .addCase(userProfile.fulfilled, (state, { payload }) => {
        state.profile.loading = false;
        state.profile.data = payload;
      })
      .addCase(activeDeactiveUser.fulfilled, (state, { payload }) => {
        state.profile.data.status = state.profile.data.status === 2 ? 1 : 2;
        toast.success(payload?.message);
      })
      .addCase(activeDeactiveUser.rejected, (state, { payload }) => {
        toast.error(payload?.message);
      });
  },
});

export const {
  updateReviewPayload,
  updateUserListingFilter,
  updateCurrentPage,
  updateSortField,
  clearUsersConfig,
} = userSlice.actions;
export default userSlice.reducer;
