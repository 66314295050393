import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../module/login/features/authSlice";
import usersSlice from "../module/users/features/usersSlice";
import dashboardSlice from "../module/dashboard/features/dashboardSlice";
import commonSlice from "./commonSlice";
import subscriptionSlice from "../module/subscription/features/subscriptionSlice";
import couponSlice from "../module/coupon/features/couponSlice";
import resourceCategorySlice from "../module/resourceCategories/features/resourceCategorySlice";
import affiliateSlice from "../module/affiliate/features/affiliateSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: usersSlice,
    dashboard: dashboardSlice,
    common: commonSlice,
    subscription: subscriptionSlice,
    coupon: couponSlice,
    resourceCategory: resourceCategorySlice,
    affiliate: affiliateSlice,
  },
  devTools: true,
});

export default store;
