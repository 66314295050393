import "react-datepicker/dist/react-datepicker.css";
import styles from "./CreateCoupon.module.scss";

import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import Breadcrumb from "../../../components/Breadcrumb";
import FormikTextField from "../../../components/FormikTextField";
import routesConstants from "../../../routes/routeConstant";
import { fetchSubscriptionPlansThunk } from "../../../store/commonSlice";
import CouponDiscount from "../components/CouponDiscount";
import CouponEmails from "../components/CouponEmails";
import PlanListing from "../components/PlanListing";
import FormikTextarea from "../../../components/FormikTextarea";
import { toast } from "react-toastify";
import { axiosPOCAdmin } from "../../../services/api";
import { ADD_UPDATE_COUPON_URL } from "../../../constants/url_helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// type
const initialValues = {
  plan_id: null,
  plan_duration_type: null,
  code: "",
  limit: "",
  expiry_date: null,
  is_private: false,
  code_description: "",
  emails: [
    {
      uuid: uuid(),
      email: "",
    },
  ],
  discount_type: null,
  discount_amount: "",
};

const CreateCoupon = () => {
  const {
    list: plans,
    loading,
    error,
  } = useSelector((state) => state?.common?.subscriptionPlans);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSubscriptionPlansThunk());
  }, [dispatch]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      code: yup
        .string("Coupon code is required.")
        .trim()
        .required("Coupon code is required."),
      plan_id: yup.number().required("Please select a plan."),
      plan_duration_type: yup.number("Please select a plan."),
      code_description: yup
        .string("Description must be string.")
        .trim("Please enter valid description."),
      limit: yup
        .number("Please enter a valid limit.")
        .min(1, "Limit must be greater than 0.")
        .required("Please enter a valid limit."),
      discount_type: yup.number().required("Discount type is required."),
      discount_amount: yup
        .number()
        .when("discount_type", {
          is: 1,
          then: () =>
            yup
              .number()
              .transform((value) => +value || 0)
              .min(1, "Discount value must be higher than 0.")
              .max(
                plans?.find((p) => p?.price)?.price,
                "Discount price can not be higher than selected plan's price."
              )
              .required("Please enter discount value."),
        })
        .when("discount_type", {
          is: 2,
          then: () =>
            yup
              .number()
              .transform((value) => +value || 0)
              .min(1, "Discount value must be higher than 0.")
              .max(100, "Discount can't be more than 100%.")
              .required("Please enter discount value."),
        })
        .when("discount_type", {
          is: 3,
          then: () =>
            yup
              .number()
              .transform((value) => +value || 0)
              .min(1, "Discount value must be higher than 0.")
              .max(365 * 2, "Free trials days can't exceed 2 years.")
              .required("Please enter discount value."),
        }),
      expiry_date: yup
        .number("Please select expiry date of the coupon.")
        .min(
          new Date().getTime(),
          "Please select valid expiry date of the coupon."
        )
        .nullable(),
      is_private: yup.boolean(),
      emails: yup.array().when("is_private", {
        is: true,
        then: () =>
          yup.array().of(
            yup.object().shape({
              email: yup
                .string()
                .trim()
                .matches(
                  /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i,
                  {
                    message: "Please enter valid email",
                  }
                )
                .required("Please enter valid email"),
            })
          ),
      }),
    });
  }, [plans]);

  const {
    setFieldValue,
    validateField,
    dirty,
    errors,
    submitForm,
    isSubmitting,
    values,
    touched,
    handleBlur,
    setFieldTouched,
  } = useFormik({
    validateOnMount: false,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const payload = {
          name: values.code,
          description: values.code_description.trim(),
          code: values.code,
          usage_limit: +values.limit,
          valid_from: moment().format("YYYY-MM-DD"),
          valid_till: values.expiry_date
            ? moment(values.expiry_date).format("YYYY-MM-DD")
            : null,
          removed_emails: [],
          plan_id: values.plan_id,
        };
        // "offered_discount": 1000,
        // "type": 1,
        switch (values.discount_type) {
          case 1:
            const plan = plans?.find((p) => p?.id === values.plan_id);
            if (plan) {
              payload.offered_discount =
                +values.discount_amount >= plan?.price
                  ? 0
                  : +values.discount_amount;
            }
            payload.type = 1;
            break;
          case 2:
            payload.offered_discount =
              +values.discount_amount >= 100 ? 0 : +values.discount_amount;
            payload.type = 2;

            break;
          case 3:
            payload.offered_discount =
              +values.discount_amount >= 365 * 2
                ? 365 * 2
                : +values.discount_amount;
            payload.type = 0;

            break;
          default:
            break;
        }

        if (values.is_private) {
          payload.emails = values.emails.map((e) => e.email);
        } else {
          payload.emails = [];
        }

        const res = await axiosPOCAdmin.post(ADD_UPDATE_COUPON_URL, payload);
        const data = await res.data;
        setSubmitting(false);
        toast.success("Coupon created successfully.");
        navigate(
          `/${routesConstants.COUPON}/${data?.data?.coupon?.poc_coupon_id}`
        );
      } catch (e) {
        toast.error(e?.response?.data?.message || e?.message);
        setSubmitting(false);
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <div className={styles.wrap}>
      <Breadcrumb
        title={"Create"}
        extraLink={[
          {
            link: "/" + routesConstants.COUPON,
            title: "Coupon",
          },
        ]}
      />
      <div className={styles.content}>
        <Card>
          <p className={styles.title}>Create Coupon</p>
          <div className={styles.fields}>
            <Row className="gap-3 gap-lg-0 ">
              <Col xs={12} lg={6} className="d-flex flex-column gap-4">
                <div className={styles.section}>
                  <FormikTextField
                    value={values.code}
                    placeholder="Enter coupon code"
                    error={errors.code}
                    title="Coupon"
                    touched={touched.code}
                    name="code"
                    onChange={(e) => {
                      const name = e.target.name;
                      setFieldValue(
                        name,
                        e.target.value?.trim()?.toUpperCase() || ""
                      ).then(() => validateField(name));
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.section}>
                  <FormikTextarea
                    value={values.code_description}
                    placeholder="Enter coupon description"
                    error={errors.code_description}
                    title="Coupon Description"
                    touched={touched.code_description}
                    name="code_description"
                    onChange={(e) => {
                      const name = e.target.name;
                      setFieldValue(name, e.target.value || "").then(() =>
                        validateField(name)
                      );
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.section}>
                  <div
                    className={styles.private_checkbox}
                    onClick={() => {
                      setFieldValue("is_private", !values.is_private);
                    }}
                  >
                    <div className={styles.checkbox}>
                      {values.is_private ? (
                        <MdOutlineCheckBox />
                      ) : (
                        <MdCheckBoxOutlineBlank />
                      )}
                    </div>
                    <div>
                      <div>Mark as Private</div>
                      <div className="text-secondary text-xs">
                        Only allow coupon for specific Emails
                      </div>
                    </div>
                  </div>
                </div>
                {values.is_private ? (
                  <div className={styles.section}>
                    <CouponEmails
                      emails={values.emails}
                      touched={touched.emails}
                      errors={errors.emails}
                      onAdd={() => {
                        setFieldValue("emails", [
                          ...values.emails,
                          {
                            uuid: uuid(),
                            email: "",
                          },
                        ]);
                      }}
                      onRemove={(uuid) => {
                        if (values.emails.length > 1) {
                          setFieldValue(
                            "emails",
                            values.emails.filter((e) => e.uuid !== uuid)
                          ).then(() => validateField("emails"));
                        }
                      }}
                      onChange={(e) => {
                        const name = e.target.name;
                        setFieldValue(name, e.target.value?.trim() || "").then(
                          () => validateField(name)
                        );
                      }}
                      handleBlur={handleBlur}
                    />
                  </div>
                ) : null}
              </Col>
              <Col xs={12} lg={6} className="d-flex flex-column gap-4">
                <div className={styles.section}>
                  <div className={styles.section_title}>Select Plan</div>
                  <PlanListing
                    planID={values.plan_id}
                    plans={plans}
                    loading={loading}
                    errorAPI={error}
                    error={errors.plan_id}
                    touched={touched.plan_id}
                    onPlanClick={(plan) => {
                      if (plan.id === values?.plan_id) {
                        return null;
                      }
                      setFieldValue("plan_id", plan?.id).then(() =>
                        validateField("plan_id")
                      );
                      setFieldValue(
                        "plan_duration_type",
                        plan?.duration_type
                      ).then(() => validateField("plan_duration_type"));
                      setFieldValue(
                        "discount_type",
                        [1, 2].includes(plan?.duration_type) ? 3 : 1
                      );
                      setFieldValue("discount_amount", "");
                    }}
                  />
                </div>
                {values.plan_id ? (
                  <div className={styles.section}>
                    <CouponDiscount
                      duration_type={values.plan_duration_type}
                      plan_id={values.plan_id}
                      discount_amount={values.discount_amount}
                      discount_type={values.discount_type}
                      error={errors.discount_amount}
                      touched={touched.discount_amount}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      validateField={validateField}
                      handleBlur={handleBlur}
                    />
                  </div>
                ) : null}
                <div className={styles.section}>
                  <div className={styles.limit_wrap}>
                    <FormikTextField
                      type="number"
                      onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        if (value?.length < 16) {
                          setFieldValue(
                            name,
                            value ? (+value).toString() : ""
                          ).then(() => validateField(name));
                        }
                      }}
                      name="limit"
                      onBlur={handleBlur}
                      value={values.limit}
                      error={errors.limit}
                      touched={touched.limit}
                      title="Usage Limit"
                      min={0}
                    />
                  </div>
                </div>
                <div className={styles.section}>
                  <div className={styles.date_wrapper}>
                    <span className={styles.field_name}>Expiry Date</span>
                    <DatePicker
                      minDate={new Date()}
                      onChange={(date) => {
                        setFieldTouched("expiry_date");
                        setFieldValue(
                          "expiry_date",
                          date ? date.getTime() : null
                        ).then(() => {
                          validateField("expiry_date");
                        });
                      }}
                      wrapperClassName={
                        errors.expiry_date && touched.expiry_date
                          ? styles.error
                          : ""
                      }
                      selected={
                        values.expiry_date ? new Date(values.expiry_date) : null
                      }
                      showMonthDropdown
                    />
                    {touched.expiry_date && errors.expiry_date ? (
                      <span className={styles.error_text}>
                        {errors.expiry_date}
                      </span>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.submit_btn_wrap}>
            <button
              className="btn"
              onClick={submitForm}
              disabled={!dirty || isSubmitting}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};
export default CreateCoupon;
